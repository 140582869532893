<template>
  <van-button @click="getlogin" :disabled="disabled" class="verify-code" size="small" type="primary">{{btnText}}</van-button>
</template>

<script>
import {Notify, Toast} from "vant";
import {sendCode} from "@/api/user";

export default {
  name: "countDown",
  props:{
    mobile:'',
    type:''
  },
  data(){
    return {
      disabled: false,
      interval:undefined,
      totalCount:0,
    }
  },
  computed:{
    btnText:function () {
      return this.totalCount !==0? `${this.totalCount}秒再次获取`: "获取验证码"
    }
  },
  methods:{
    getCode(){
      sendCode({"mobile":this.mobile,"type":this.type}).then((res) => {
        if (res.success) {
          Notify({
            type: "success",
            message:"发送成功"
          });
        }
      })
    },
    getlogin(){
      if (!this.mobile)
      {
        Notify('请输入手机号!');
        return
      }
      // 按钮60秒倒计时
      this.disabled=true
      this.totalCount=60
      this.getCode()  //60秒过倒计时过后才能调用的事件
      this.interval=setInterval(()=>{
        this.totalCount--
        if(this.totalCount === 0){
          clearInterval(this.interval)
          this.disabled=false
        }
      },1000);
    }

  },
}
</script>

<style scoped>
.verify-code{
  position: relative;
}
.verify-code .verify-btn{
  position: absolute;
  right: 0;
  top:50%;
  transform: translate(0,-50%);
}
</style>
