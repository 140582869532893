<template>
  <div>
    <van-nav-bar
        title="绑定手机"
        :border="false"
        left-arrow
    >
    </van-nav-bar>
    <van-form>
      <van-field
          v-model="mobile"
          name="手机号"
          label="手机号"
          placeholder="请输入手机号"
      />
      <van-field
          v-model="code"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
      >
        <template #button>
          <count-down class="verify-btn" :mobile="mobile" :type="1"></count-down>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button @click="onSubmit" round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {Toast} from "vant";
import {bindMobile} from "@/api/user";
import countDown from "@/components/countDown/countDown";

export default {
  name: "bind",
  components:{
    countDown
  },
  data() {
    return {
      mobile: '',
      code:'',
      list: [],
      showPicker: false,
    }
  },
  methods:{
    onSubmit(){
      if (!this.mobile)
      {
        return this.$toast.fail('请输入电话号码')
      }
      if (!this.code)
      {
        return this.$toast.fail('请输入验证码')
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      bindMobile({"mobile":this.mobile,"code":this.code}).then((res) => {
        if (res.code == 0) {

        }
      })
    }
  }
}
</script>

<style scoped>

</style>